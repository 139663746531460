exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-calculator-template-jsx": () => import("./../../../src/templates/calculator-template.jsx" /* webpackChunkName: "component---src-templates-calculator-template-jsx" */),
  "component---src-templates-contact-template-jsx": () => import("./../../../src/templates/contact-template.jsx" /* webpackChunkName: "component---src-templates-contact-template-jsx" */),
  "component---src-templates-default-template-jsx": () => import("./../../../src/templates/default-template.jsx" /* webpackChunkName: "component---src-templates-default-template-jsx" */),
  "component---src-templates-get-started-template-jsx": () => import("./../../../src/templates/get-started-template.jsx" /* webpackChunkName: "component---src-templates-get-started-template-jsx" */),
  "component---src-templates-guide-details-template-jsx": () => import("./../../../src/templates/guide-details-template.jsx" /* webpackChunkName: "component---src-templates-guide-details-template-jsx" */),
  "component---src-templates-guide-landing-template-jsx": () => import("./../../../src/templates/guide-landing-template.jsx" /* webpackChunkName: "component---src-templates-guide-landing-template-jsx" */),
  "component---src-templates-home-template-jsx": () => import("./../../../src/templates/home-template.jsx" /* webpackChunkName: "component---src-templates-home-template-jsx" */),
  "component---src-templates-news-details-template-jsx": () => import("./../../../src/templates/news-details-template.jsx" /* webpackChunkName: "component---src-templates-news-details-template-jsx" */),
  "component---src-templates-static-template-jsx": () => import("./../../../src/templates/static-template.jsx" /* webpackChunkName: "component---src-templates-static-template-jsx" */),
  "component---src-templates-team-template-jsx": () => import("./../../../src/templates/team-template.jsx" /* webpackChunkName: "component---src-templates-team-template-jsx" */)
}

